const styles = (theme) => ({
    root: {
        paddingTop: 28,
        height: '100%',
        flexDirection: 'column',
        textAlign: 'center',
    },
    voteHeader: {
        fontWeight: 'heavy',
        fontSize: 28,
    },
    voteHeaderContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
    },
    actionContainer: {
        display: 'flex',
        flexDirection: 'row',
        marginLeft: 10,
    },
});

export default styles;
