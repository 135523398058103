import { datadogRum } from '@datadog/browser-rum';

export const initMonitoring = () => {
    const applicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
    const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
    const site = process.env.REACT_APP_DATADOG_SITE;
    const service = process.env.REACT_APP_DATADOG_SERVICE;
    const version = process.env.REACT_APP_VERSION;
    const sampleRate = Number.parseInt(
        process.env.REACT_APP_DATADOG_SAMPLE_RATE,
    );
    const trackInteractions =
        process.env.REACT_APP_DATADOG_SAMPLE_RATE === 'true';

    if (applicationId && clientToken && site && service) {
        datadogRum.init({
            applicationId,
            clientToken,
            site,
            service,
            // Specify a version number to identify the deployed version of your application in Datadog
            version,
            sampleRate,
            trackInteractions,
        });
    }
};
