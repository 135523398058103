const styles = (theme) => ({
    root: {
        width: 700,
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: 300,
        },
    },
    headerContainer: {
        backgroundColor: '#545454',
        color: '#fafafa',
    },
    sectionHeader: {
        paddingTop: 8,
        paddingLeft: 4,
    },
    boldHeader: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    nominationContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    nomination: {
        paddingTop: 20,
        paddingBottom: 20,
    },
    oddNomination: {
        backgroundColor: '#f0f0f0',
    },
});

export default styles;
