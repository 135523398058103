import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import { Paper } from '@mui/material';

const VoteProfile = ({ classes, name, reasons }) => {
    return (
        <Paper className={classes.root} elevation={20}>
            <div className={classes.headerContainer}>
                <div
                    className={clsx(classes.sectionHeader, classes.boldHeader)}
                >
                    {name}
                </div>
                <div
                    className={clsx(classes.sectionHeader, classes.boldHeader)}
                >
                    Nominations
                </div>
            </div>
            <div className={classes.nominationContainer}>
                {reasons.map((item, i) => {
                    return (
                        <div
                            key={i}
                            className={clsx(
                                classes.nomination,
                                i % 2 === 0 && classes.oddNomination,
                            )}
                        >
                            <div>{`"${item.reason}"`}</div>
                            <div>{`Nominated By: ${item.nominator}`}</div>
                        </div>
                    );
                })}
            </div>
        </Paper>
    );
};

VoteProfile.propTypes = {
    classes: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    reasons: PropTypes.array.isRequired,
};

export default withStyles(styles)(VoteProfile);
