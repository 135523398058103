import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import HomeScreen from './common/HomeScreen';
import { withLayout } from './common/layout/connect';
import VoteScreen from './common/VoteScreen';
import AdminScreen from './common/AdminScreen';

function Router({ history }) {
    return (
        <ConnectedRouter history={history}>
            <Switch>
                <Route path="/home">{withLayout(HomeScreen)}</Route>
                <Route path="/vote">{withLayout(VoteScreen)}</Route>
                <Route path="/admin">{withLayout(AdminScreen)}</Route>
                <Redirect to="/home" />
            </Switch>
        </ConnectedRouter>
    );
}

Router.propTypes = {
    history: PropTypes.object.isRequired,
};

export default Router;
