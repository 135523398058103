import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';
import VoteProfile from '../VoteProfile';
import clsx from 'clsx';

const VotingPanel = ({
    classes,
    nominations,
    votes,
    setVotes,
    currentVote,
    setCurrentVote,
}) => {
    const handleVoteClick = (clickedName) => {
        if (currentVote < 3) {
            votes[currentVote] = clickedName;
            setVotes(votes);
            setCurrentVote(currentVote + 1);
        }
    };

    return (
        <div className={classes.root}>
            {nominations.map(({ name, reasons }, i) => {
                return (
                    <div
                        key={i}
                        className={clsx(
                            classes.profile,
                            name === votes[0] && classes.profileFirst,
                            name === votes[1] && classes.profileSecond,
                            name === votes[2] && classes.profileThird,
                        )}
                        onClick={(e) => {
                            handleVoteClick(name);
                        }}
                    >
                        <VoteProfile name={name} reasons={reasons} />
                    </div>
                );
            })}
        </div>
    );
};

VotingPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    nominations: PropTypes.array.isRequired,
    votes: PropTypes.array.isRequired,
    setVotes: PropTypes.func.isRequired,
    currentVote: PropTypes.number.isRequired,
    setCurrentVote: PropTypes.func.isRequired,
};

export default withStyles(styles)(VotingPanel);
