import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';

const HomeScreen = ({ classes }) => {
    return (
        <div
            className={classes.root}
        >{`Welcome to Humblebrags! This website works on the honor code. 
    If you're Karen or know what you're doing, use the admin panel to start a voting period. Otherwise cast
    your vote on the voting tab!`}</div>
    );
};

HomeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(HomeScreen);
