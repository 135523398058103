const styles = (theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
        backgroundColor: '#fafafa',
    },
    nav: {
        marginBottom: 20,
    },

    mainContainer: {
        paddingLeft: 0,
    },
    version: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
});

export default styles;
