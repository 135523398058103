import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@mui/styles';

const NavItem = ({ classes, children, route }) => {
    return (
        <NavLink className={classes.root} to={route}>
            {children}
        </NavLink>
    );
};

NavItem.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
    route: PropTypes.string.isRequired,
};

NavItem.defaultProps = {
    children: null,
};

export default withStyles(styles)(NavItem);
