const styles = (theme) => ({
    root: {
        width: 128,
        textAlign: 'center',
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 16,
        paddingBottom: 16,
        '&:hover': {
            backgroundColor: '#fafafa',
            cursor: 'pointer',
            color: '#000000',
        },
    },
});

export default styles;
