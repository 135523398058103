import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';

const Screen = ({ classes }) => {
    return <div className={classes.root}>Test</div>;
};

Screen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Screen);
