const styles = (theme) => ({
    root: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
    },
    header: {
        paddingBottom: 16,
    },
    textField: {
        marginBottom: 20,
    },
});

export default styles;
