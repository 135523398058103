import React from 'react';
import styles from './styles.js';
import PropTypes from 'prop-types';
import NavBar from '../NavBar';
import { withStyles } from '@mui/styles';

const Layout = ({ children, classes }) => {
    return (
        <div className={classes.root}>
            <NavBar />
            <div className={classes.mainContainer}>{children}</div>
        </div>
    );
};

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    children: PropTypes.node,
};

Layout.defaultProps = {
    children: null,
};

export default withStyles(styles)(Layout);
