const styles = (theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
            width: 303,
        },
    },
    profile: {
        marginTop: 15,
        marginBottom: 15,
        '&:hover': {
            cursor: 'pointer',
            border: '1px solid black',
        },
    },
    profileFirst: {
        border: '3px solid #C9B037',
        '&:hover': {
            cursor: 'pointer',
            border: '3px solid #C9B037',
        },
    },
    profileSecond: {
        border: '3px solid #B4B4B4',
        '&:hover': {
            cursor: 'pointer',
            border: '3px solid #B4B4B4',
        },
    },
    profileThird: {
        border: '3px solid #AD8A56',
        '&:hover': {
            cursor: 'pointer',
            border: '3px solid #AD8A56',
        },
    },
});

export default styles;
