import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';
import VotingPanel from '../VotingPanel';
import { Button } from '@mui/material';
import { nominations } from './consts';

const VoteScreen = ({ classes }) => {
    const [votingActive, setVotingActive] = useState(true);
    const [votes, setVotes] = useState(['', '', '']);
    const [currentVote, setCurrentVote] = useState(0);

    useEffect(() => {
        // eslint-disable-next-line no-constant-condition
        if (false) {
            fetch(`${process.env.REACT_APP_API_URL}/active`)
                .then((response) => response.json())
                .then((data) => {
                    setVotingActive(data.voting);
                });
        }
    }, []);

    const handleResetVotes = () => {
        setVotes(['', '', '']);
        setCurrentVote(0);
    };

    const handleSubmitVotes = () => {
        fetch(`${process.env.REACT_APP_API_URL}/vote/`, {
            method: 'POST',
            body: JSON.stringify({ vote_array: votes }),
        })
            .then((response) => response.json())
            .then((data) => {
                // do nothing
            });
    };

    return (
        <div className={classes.root}>
            {votingActive ? (
                <div>
                    <div className={classes.voteHeaderContainer}>
                        <div className={classes.voteHeader}>
                            {votes[0] === ''
                                ? `Pick your top 3!`
                                : `1st: ${votes[0]} 2nd: ${votes[1]} 3rd: ${votes[2]}`}
                        </div>
                        <div className={classes.actionContainer}>
                            <Button
                                onClick={() => {
                                    handleResetVotes();
                                }}
                            >
                                RESET
                            </Button>
                            {currentVote === 3 && (
                                <Button
                                    onClick={() => {
                                        handleSubmitVotes();
                                    }}
                                >
                                    SUBMIT
                                </Button>
                            )}
                        </div>
                    </div>
                    <VotingPanel
                        nominations={nominations}
                        votes={votes}
                        setVotes={setVotes}
                        currentVote={currentVote}
                        setCurrentVote={setCurrentVote}
                    />
                </div>
            ) : (
                <div>Voting is Closed</div>
            )}
        </div>
    );
};

VoteScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VoteScreen);
