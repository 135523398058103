import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';
import { Button, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    email: yup
        .string('Enter the Start Date')
        .trim('Enter a valid email')
        .matches(
            /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d$/,
            'Format Must Be YYYY-MM-DDTHH:mm:ss',
        ),
    password: yup
        .string('Enter the End Date')
        .trim()
        .matches(
            /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d$/,
            'Format Must Be YYYY-MM-DDTHH:mm:ss',
        ),
});

const AdminPanel = ({ classes }) => {
    const formik = useFormik({
        initialValues: {
            startDate: '2022-03-01T00:00:00',
            endDate: '2022-04-01T00:00:00',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            fetch(`${process.env.REACT_APP_API_URL}/startEvent/`, {
                method: 'POST',
                body: JSON.stringify(values, null, 2),
            })
                .then((response) => response.json())
                .then((data) => {
                    // do nothing
                });
        },
    });

    return (
        <div className={classes.root}>
            <div className={classes.header}>Start a Voting Session!</div>
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    id="startDate"
                    name="startDate"
                    label="Start Date"
                    onChange={formik.handleChange}
                    value={formik.initialValues.startDate}
                />
                <TextField
                    fullWidth
                    id="endDate"
                    name="endDate"
                    label="End Date"
                    onChange={formik.handleChange}
                    className={classes.textField}
                    value={formik.initialValues.endDate}
                />
                <Button
                    color="primary"
                    variant="contained"
                    fullWidth
                    type="submit"
                >
                    Submit
                </Button>
            </form>
        </div>
    );
};

AdminPanel.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminPanel);
