import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import NavItem from './NavItem';
import { withStyles } from '@mui/styles';

const NavBar = ({ classes }) => {
    return (
        <div className={classes.root}>
            <NavItem route="/home">HOME</NavItem>
            <NavItem route="/vote">VOTE</NavItem>
            <NavItem route="/admin">ADMIN</NavItem>
        </div>
    );
};

NavBar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(NavBar);
