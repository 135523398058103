import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles';
import { withStyles } from '@mui/styles';
import AdminPanel from '../AdminPanel';

const AdminScreen = ({ classes }) => {
    const [votingActive, setVotingActive] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line no-constant-condition
        if (false) {
            fetch(`${process.env.REACT_APP_API_URL}/active`)
                .then((response) => response.json())
                .then((data) => {
                    setVotingActive(data.voting);
                });
        }
    }, []);

    return (
        <div className={classes.root}>
            {votingActive ? (
                <div>
                    {`A voting session is active already! Sadly we don't have any way to stop it built in so you'll need to wait`}
                </div>
            ) : (
                <AdminPanel setVotingActive={setVotingActive} />
            )}
        </div>
    );
};

AdminScreen.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdminScreen);
