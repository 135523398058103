export const nominations = [
    {
        name: 'Leo',
        reasons: [
            { reason: "He's a very good worker", nominator: 'Rob' },
            { reason: 'He has solved some bugs', nominator: 'Rob' },
            { reason: 'I am in New Orleans right now lol', nominator: 'Rob' },
        ],
    },
    {
        name: 'Alex',
        reasons: [
            { reason: "He sometimes come's into the office", nominator: 'Rob' },
            { reason: 'He plays card games', nominator: 'Rob' },
        ],
    },
    {
        name: 'Rob',
        reasons: [{ reason: 'Yes', nominator: 'Rob' }],
    },
    {
        name: 'Dan',
        reasons: [
            { reason: 'I like his beard', nominator: 'Rob' },
            {
                reason: 'How would we ever get the TVs or internet to work',
                nominator: 'Rob',
            },
            { reason: 'Fast cars vroom', nominator: 'Rob' },
        ],
    },
    {
        name: 'Michelle',
        reasons: [{ reason: 'Where did Michelle go?', nominator: 'Rob' }],
    },
];
