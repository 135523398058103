const styles = (theme) => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        textAlign: 'center',
        justifyContent: 'flex-start',
        backgroundColor: '#e5e5e5',
    },
});

export default styles;
