import React from 'react';
import { Provider as StoreProvider } from 'react-redux';
import createStore from './store';
import GlobalStyles from './theme/GlobalStyles';
import Router from './Router';
import { ConnectedAppGate } from './init/AppGate';
import Screen from './common/Screen';
import ErrorBoundary from './common/errors/ErrorBoundary';
import { initMonitoring } from './monitoring';
import { ThemeProvider, createTheme } from '@mui/material';

const { store, history } = createStore();

// For Datadog Browser RUM
initMonitoring();

const theme = createTheme();

function App() {
    return (
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <GlobalStyles />
                <ErrorBoundary component={Screen}>
                    <ConnectedAppGate>
                        {() => <Router history={history} />}
                    </ConnectedAppGate>
                </ErrorBoundary>
            </ThemeProvider>
        </StoreProvider>
    );
}

export default App;
